import React, { useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";

function AddData({ open, setOpen, handleAddData, clientId }) {
  const [formData, setFormData] = useState({
    kg: "",
    bodyFat: "",
    water: "",
    muscle: "",
    fat: "",
    kcal: "",
    metabolism: "",
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    const data = {
      date: Date.now(),
      client: clientId,
      kg: formData.kg,
      bodyFat: formData.bodyFat,
      water: formData.water,
      muscle: formData.muscle,
      fat: formData.fat,
      kcal: formData.kcal,
      metabolism: formData.metabolism,
    };
    handleAddData(data);
    setOpen(false);
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Dodaj podatke</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              name="kg"
              label="Kg"
              type="text"
              fullWidth
              variant="standard"
              value={formData.kg}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              name="bodyFat"
              label="% telesne masti"
              type="text"
              fullWidth
              variant="standard"
              value={formData.bodyFat}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              name="water"
              label="% vode"
              type="text"
              fullWidth
              variant="standard"
              value={formData.water}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              name="muscle"
              label="% mišića"
              type="text"
              fullWidth
              variant="standard"
              value={formData.muscle}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              name="fat"
              label="% viscelarne masti"
              type="text"
              fullWidth
              variant="standard"
              value={formData.fat}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              name="kcal"
              label="Kalorije"
              type="text"
              fullWidth
              variant="standard"
              value={formData.kcal}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              name="metabolism"
              label="Godine metabolizma"
              type="text"
              fullWidth
              variant="standard"
              value={formData.metabolism}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="error" onClick={handleClose}>
          Otkaži
        </Button>
        <Button variant="contained" color="success" onClick={handleSave}>
          Dodaj
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddData;
