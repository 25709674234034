import React, { useState, useCallback } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from "@mui/material";

// Hook that provides confirmation dialog logic and component
function useConfirmationDialog() {
  const [open, setOpen] = useState(false);
  const [dialogProps, setDialogProps] = useState({});

  const requestConfirmation = useCallback((props = {}) => {
    setOpen(true);
    setDialogProps(props);
  }, []);

  const handleConfirm = useCallback(() => {
    if (dialogProps.onConfirm) {
      dialogProps.onConfirm();
    }
    setOpen(false);
  }, [dialogProps]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const renderDialog = () => (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"sm"}>
      <DialogContent>
        <DialogContentText id="confirmation-dialog-description">
          {dialogProps.content || "Da li ste sigurni?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="error" variant="contained">
          Ne
        </Button>
        <Button onClick={handleConfirm} color="success" variant="contained">
          Da
        </Button>
      </DialogActions>
    </Dialog>
  );

  return { requestConfirmation, renderDialog };
}

export default useConfirmationDialog;
