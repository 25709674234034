import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
  deleteDoc,
  addDoc,
  orderBy,
} from "firebase/firestore";
import { Link, navigate } from "gatsby";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { db } from "../../utils/firebase";
import Layout from "../../components/Layout";
import * as styles from "./styles.module.css";
import useConfirmationDialog from "../../hooks/useConfirmation";
import AddData from "../../components/AddData";

function User(props) {
  const { id } = props;
  const [client, setClient] = useState({});
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { requestConfirmation, renderDialog } = useConfirmationDialog();

  const fetchClientById = async () => {
    setIsLoading(true);
    const clientDoc = doc(db, "clients", id);

    try {
      const docSnap = await getDoc(clientDoc);
      setIsLoading(false);
      if (docSnap.exists()) {
        setClient(docSnap.data());
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error getting document:", error);
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    const dataCollection = collection(db, "data");
    const q = query(
      dataCollection,
      where("client", "==", id),
      orderBy("date", "asc")
    );

    try {
      const querySnapshot = await getDocs(q);
      const fetchedDocuments = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setData(fetchedDocuments);
    } catch (err) {
      console.error("Error fetching documents:", err);
    }
  };

  useEffect(() => {
    fetchClientById();
    fetchData();
  }, []);

  const deleteClient = async () => {
    const clientRef = doc(db, "clients", id);

    try {
      await deleteDoc(clientRef);
      navigate("/klijenti");
    } catch (error) {
      console.error("Error removing document: ", error);
    }
  };

  const deleteData = async (dataId) => {
    const dataRef = doc(db, "data", dataId);

    try {
      await deleteDoc(dataRef);
      fetchData();
    } catch (error) {
      console.error("Error removing document: ", error);
    }
  };

  const handleDelete = () => {
    requestConfirmation({
      content: "Da li ste sigurni?",
      onConfirm: deleteClient,
    });
  };

  const handleDeleteData = (dataId) => {
    requestConfirmation({
      content: "Da li ste sigurni?",
      onConfirm: () => deleteData(dataId),
    });
  };

  const handleAddData = async (newData) => {
    try {
      await addDoc(collection(db, "data"), newData);
      fetchData();
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <Layout>
      <div className={`${styles.container} rmPM`}>
        {isLoading ? (
          <CircularProgress className="spinner" color="success" />
        ) : (
          <>
            <div className={styles.header}>
              <h1 className={styles.title}>{client.name}</h1>
              <div className="buttonsContainer">
                <Link to="/klijenti">
                  <Button
                    className={styles.button}
                    color="primary"
                    variant="outlined"
                  >
                    Nazad
                  </Button>
                </Link>
                <Button
                  className={styles.button}
                  color="primary"
                  variant="contained"
                  onClick={() => window.print()}
                >
                  Štampaj
                </Button>
                <Button
                  className={styles.button}
                  color="success"
                  variant="contained"
                  onClick={() => setOpen(true)}
                >
                  Dodaj podatke
                </Button>
                <Button
                  className={styles.button}
                  color="error"
                  variant="contained"
                  onClick={handleDelete}
                >
                  Izbriši klijenta
                </Button>
              </div>
            </div>
            <div>Visina: {client.data?.height} cm</div>
            <div className={`${styles.dataDisplay} wrap`}>
              <div className={styles.labels}>
                <p>
                  <span className={styles.date}>Datum</span>
                </p>
                <p>Kg</p>
                <p>% masti</p>
                <p>% vode</p>
                <p>% mišića</p>
                <p>% viscelarne masti</p>
                <p>kcal</p>
                <p>Godine metabolizma</p>
              </div>

              {client.data && (
                <div className={styles.data}>
                  <p>
                    <span className={styles.date}>
                      {new Date(client.data.date).toLocaleDateString("sr-SR")}
                    </span>
                  </p>
                  <p>{client.data.kg || "/"}</p>
                  <p>{client.data.bodyFat || "/"}</p>
                  <p>{client.data.water || "/"}</p>
                  <p>{client.data.muscle || "/"}</p>
                  <p>{client.data.fat || "/"}</p>
                  <p>{client.data.kcal || "/"}</p>
                  <p>{client.data.metabolism || "/"}</p>
                </div>
              )}

              {data &&
                data.map((data) => (
                  <div className={styles.data} key={data.date}>
                    <p>
                      <span className={styles.date}>
                        {new Date(data.date).toLocaleDateString("sr-SR")}
                      </span>
                    </p>
                    <p>{data.kg || "/"}</p>
                    <p>{data.bodyFat || "/"}</p>
                    <p>{data.water || "/"}</p>
                    <p>{data.muscle || "/"}</p>
                    <p>{data.fat || "/"}</p>
                    <p>{data.kcal || "/"}</p>
                    <p>{data.metabolism || "/"}</p>
                    <Button
                      className="hidePrint"
                      color="error"
                      size="small"
                      variant="outlined"
                      onClick={() => handleDeleteData(data.id)}
                    >
                      Izbriši
                    </Button>
                  </div>
                ))}
            </div>
            <div className={styles.note}>
              <h5>Napomena:</h5>
              <blockquote>{client.note}</blockquote>
            </div>
            {renderDialog()}
            <AddData
              clientId={id}
              open={open}
              setOpen={setOpen}
              handleAddData={handleAddData}
            />
          </>
        )}
      </div>
    </Layout>
  );
}

export default User;

export const Head = () => <meta name="robots" content="noindex, nofollow" />;
